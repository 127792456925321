<template>
  <div class="tab-content">
    <ReviewsSearch :is-search-disabled="!reviews.length" @searchReviews="searchReviews" />
    <ReviewsSort :is-sort-disabled="!reviews.length" is-user-reviews @sortReviews="sortReviews" />

    <div class="review-cards-list">
      <IndividualReviewCard
        v-for="(review, i) in searchedReviews"
        :key="i"
        :review="review"
        @openReviewRateModal="openReviewRateModal(review)"
      />
    </div>

    <v-progress-circular
      v-if="isLoading"
      class="loader-main"
      size="50"
      width="5"
      color="var(--text-color)"
      indeterminate
    />

    <div v-if="isEmpty" class="empty">
      Your request did not match any reviews...
    </div>

    <ScrollPaginationObserver v-if="isRenderScrollPagination" @loadMore="loadMore" />

    <ReviewsRateModal
      :is-reviews-rate-modal-open.sync="isReviewsRateModalOpen"
      :review="selectedReview"
      @resetReviews="resetReviews"
    />
  </div>
</template>

<script>
import api from '@/api'
import ReviewsSearch from '@/components/ReviewsSearch'
import ReviewsSort from '@/components/ReviewsSort'
import IndividualReviewCard from '@/components/IndividualReviewCard'
import ReviewsRateModal from '@/components/ReviewsRateModal'
import ScrollPaginationObserver from '@/components/ScrollPaginationObserver'

export default {
  name: 'ReviewsYourReviews',
  components: {
    ReviewsSearch,
    ReviewsSort,
    IndividualReviewCard,
    ReviewsRateModal,
    ScrollPaginationObserver
  },
  data() {
    return {
      isLoading: true,
      isSearching: false,
      isEmpty: false,
      isReviewsRateModalOpen: false,
      reviews: [],
      searchedReviews: [],
      reviewsParams: {
        pageNumber: 0,
        pageSize: 50,
        filterByCompany: '',
        filterByJobTitle: '',
        sortBy: ''
      },
      selectedReview: {},
      isCanShowMore: false
    }
  },
  computed: {
    ticketId() {
      return this.$route.params.ticketId
    },
    isRenderScrollPagination() {
      return !this.isEmpty && !!this.searchedReviews.length && this.isCanShowMore
    }
  },
  created() {
    this.fetchIndividualReviews()
  },
  async mounted() {
    if (this.$route.params.isOpenRateModal) {
      await this.fetchTicket()
      this.isReviewsRateModalOpen = true
      this.$route.params.isOpenRateModal = null
    }
  },
  methods: {
    async fetchTicket() {
      const { data } = await api.users.getSingleTicket(this.ticketId)
      if (data) {
        this.selectedReview = data
      }
    },
    async fetchIndividualReviews() {
      this.isLoading = true
      const { data } = await api.users.getIndividualReviews(this.reviewsParams)
      this.isLoading = false

      if (!data) return

      if (!this.isSearching) {
        this.reviews = data || []
        this.searchedReviews = [...this.searchedReviews, ...data]
        this.isCanShowMore = data.length === this.reviewsParams.pageSize
        return
      }

      this.searchedReviews = data || []
      this.isEmpty = !this.searchedReviews.length
    },
    resetReviews() {
      this.isEmpty = false
      this.searchedReviews = []
      this.reviewsParams.pageNumber = 0
      this.fetchIndividualReviews()
    },
    sortReviews(sortTitle) {
      this.reviewsParams.sortBy = sortTitle
      this.resetReviews()
    },
    searchReviews({ company, jobTitle }) {
      this.isSearching = true
      this.reviewsParams.filterByCompany = company
      this.reviewsParams.filterByJobTitle = jobTitle
      this.resetReviews()
    },
    openReviewRateModal(review) {
      this.selectedReview = review
      this.isReviewsRateModalOpen = true
    },
    loadMore() {
      this.reviewsParams.pageNumber += 1
      this.fetchIndividualReviews()
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/style/mixins.scss";

.tab-content {
  @include default-tab-content;

  background-color: var(--background-highlight-color);
}

.review-cards-list {
  @include default-review-cards-list;
}

.empty {
  margin: auto;
  width: fit-content;
}
</style>
