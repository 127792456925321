<template>
  <div>
    <v-progress-circular
      v-if="loading"
      class="loader-main"
      size="50"
      width="5"
      indeterminate
    />
    <template v-else>
      <div class="radio-group-block">
        <AppRadioGroup title="Have you heard back?" :value.sync="offerData.hasHeardBack" />
        <AppRadioGroup title="Have you received an offer?" :value.sync="offerData.hasReceivedOffer" />
      </div>
      <div class="range-sliders-block">
        <AppRangeSlider
          title="How easy was the onboarding?"
          :value.sync="offerData.howEasyOnboarding"
          :labels="['Easy', 'Neutral', 'Hard']"
        />
        <AppRangeSlider
          title="Are you happy with the offer?"
          :value.sync="offerData.howHappyOffer"
          :labels="['Yes', 'Neutral', 'No']"
        />
      </div>
      <RateAndDescription
        :current-step-short-title="currentStepShortTitle"
        :rate.sync="offerData.rate"
        :description.sync="offerData.description"
      />
    </template>
  </div>
</template>

<script>
import api from '@/api'
import RateAndDescription from '@/components/RateAndDescription'

export default {
  name: 'RateOfferOnboarding',
  components: {
    RateAndDescription
  },
  props: {
    currentStepShortTitle: {
      type: String,
      required: true
    },
    eventHub: {
      type: Object,
      required: true
    },
    ticketId: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      loading: false,
      offerData: {
        description: '',
        hasHeardBack: null,
        hasReceivedOffer: null,
        howEasyOnboarding: 1,
        howHappyOffer: 1,
        rate: 0
      }
    }
  },
  watch: {
    ticketId() {
      this.fetchOfferReview()
    }
  },
  created() {
    this.eventHub.$on('createOfferReview', this.createOfferReview)
    this.fetchOfferReview()
  },
  destroyed() {
    this.eventHub.$off('createOfferReview', this.createOfferReview)
  },
  methods: {
    async createOfferReview() {
      const { error } = await api.users.createOfferReview(this.ticketId, this.offerData)
      if (error) console.error(error)
    },
    async fetchOfferReview() {
      this.loading = true
      const { data } = await api.users.getOfferReview(this.ticketId)
      this.loading = false

      if (data) this.offerData = data
    }
  }
}
</script>

<style lang="scss" scoped>
.radio-group-block {
  display: flex;
  gap: 80px;
}

.range-sliders-block {
  display: flex;
  margin-top: 32px;
  margin-bottom: 50px;
  gap: 63px;
}
</style>
