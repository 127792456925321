<template>
  <section class="reviews">
    <PageHeader>
      Reviews
    </PageHeader>
    <section class="content">
      <ReviewsPageTabs :active-tab-id.sync="activeTabId" />
    </section>
  </section>
</template>

<script>
import ReviewsPageTabs from '@/components/ReviewsPageTabs'

export default {
  name: 'Reviews',
  components: {
    ReviewsPageTabs
  },
  data() {
    return {
      activeTabId: 3
    }
  },
  mounted() {
    if (this.$route.params.tabId) {
      this.activeTabId = this.$route.params.tabId
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/style/mixins.scss";

.reviews {
  display: grid;
  grid-template-rows: 42px auto;
  padding-top: 37px;

  @include breakpoint-reverse(medium) {
    padding: 37px 24px 0;
  }

  @include breakpoint-reverse(small) {
    padding: 12px 12px 0;
  }
}

.content {
  margin-top: 80px;

  @include breakpoint-reverse(small) {
    margin-top: 40px;
  }
}
</style>
