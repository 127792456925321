<template>
  <div class="reviews-search">
    <v-text-field
      v-model="searchFields.company"
      label="Company Name"
      type="text"
      height="40"
      dense
      solo
      flat
      rounded
      hide-details
      :disabled="isSearchDisabled"
      class="search-input"
      @input="searchReviews"
    />
    <v-text-field
      v-model="searchFields.jobTitle"
      label="Job Title"
      type="text"
      height="40"
      dense
      solo
      flat
      rounded
      hide-details
      :disabled="isSearchDisabled"
      class="search-input"
      @input="searchReviews"
    />
    <AppButton
      height="40"
      class="search-button"
      :disabled="isSearchDisabled"
      dark
      color="var(--button-primary-color)"
      @click="searchReviews"
    >
      Search
    </AppButton>
  </div>
</template>

<script>
export default {
  name: 'ReviewsSearch',
  props: {
    isSearchDisabled: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      searchFields: {
        company: '',
        jobTitle: ''
      }
    }
  },
  methods: {
    searchReviews() {
      clearTimeout(this.debounce)
      this.debounce = setTimeout(() => {
        this.$emit('searchReviews', this.searchFields)
      }, 1200)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/style/mixins.scss";

.reviews-search {
  display: grid;
  align-items: center;
  grid-template-columns: repeat(2, 220px) 138px;
  margin-bottom: 36px;
  gap: 21px;

  @include breakpoint-reverse(small) {
    grid-template-columns: 1fr;
  }
}

.search-input::v-deep {
  .v-label {
    font-size: var(--font-size-base-sm);
    color: var(--tertiary-text-color);
  }
}

.search-button {
  justify-self: center;
  width: 138px;
  box-shadow: none;

  @include breakpoint-reverse(small) {
    width: 100%;
  }

  &::v-deep {
    .text {
      font-size: var(--font-size-base-sm);
      line-height: var(--line-height-xs);
      text-shadow: none;
      color: var(--primary-button-text-color);
    }

    &.v-btn.v-btn--disabled {
      background-color: var(--button-primary-color) !important;
      opacity: 0.5;
    }
  }
}
</style>
