<template>
  <div class="reviews-page-tabs">
    <div class="tabs-list">
      <div
        v-for="(tab, i) in tabsList"
        :key="i"
        class="tab-item"
        :style="{ 'background-color': tab.color }"
        @click="activateTab(tab.id)"
      >
        {{ tab.title }}
      </div>
    </div>
    <keep-alive>
      <component :is="getActiveComponent" />
    </keep-alive>
  </div>
</template>

<script>
import ReviewsBrowseByCompany from '@/components/ReviewsBrowseByCompany'
import ReviewsBasedOnYourDashboard from '@/components/ReviewsBasedOnYourDashboard'
import ReviewsYourReviews from '@/components/ReviewsYourReviews'

export default {
  name: 'ReviewsPageTabs',
  components: {
    ReviewsBrowseByCompany,
    ReviewsBasedOnYourDashboard,
    ReviewsYourReviews
  },
  props: {
    activeTabId: {
      type: Number,
      required: false,
      default: 3
    }
  },
  data() {
    return {
      tabsList: [
        {
          id: 1,
          title: 'Browse by Companies',
          color: 'var(--background-tertiary-color)',
          component: 'ReviewsBrowseByCompany'
        },
        {
          id: 2,
          title: 'Based on your Dashboard',
          color: 'var(--background-accept-color)',
          component: 'ReviewsBasedOnYourDashboard'
        },
        {
          id: 3,
          title: 'Your Reviews',
          color: 'var(--background-highlight-color)',
          component: 'ReviewsYourReviews'
        }
      ]
    }
  },
  computed: {
    getActiveComponent() {
      const activeTab = this.tabsList.find(tab => tab.id === this.activeTabId)
      return activeTab.component
    }
  },
  methods: {
    activateTab(tabId) {
      this.$emit('update:activeTabId', tabId)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/style/mixins.scss";

.reviews-page-tabs {
  height: 100%;

  @include breakpoint-reverse(small) {
    margin: 0 -12px;
  }
}

.tabs-list {
  display: grid;
  justify-content: center;
  grid-template-columns: repeat(3, 331px);
  overflow: hidden;
  gap: 39px;

  @include breakpoint-reverse(small) {
    grid-template-columns: repeat(3, 125px);
    gap: 0;
  }
}

.tab-item {
  border-top-left-radius: var(--border-radius-default-sm);
  border-top-right-radius: var(--border-radius-default-sm);
  padding: 13px 20px;
  box-shadow: var(--text-shadow-default);
  font-weight: var(--font-weight-bold);
  font-size: var(--font-size-sub);
  text-align: center;
  cursor: pointer;

  @include breakpoint-reverse(small) {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px 10px;
    font-size: var(--font-size-base);
  }
}
</style>
