<template>
  <div class="tab-content">
    <ReviewsSearch :is-search-disabled="!companyReviews.length" @searchReviews="searchReviews" />
    <ReviewsSort :is-sort-disabled="!companyReviews.length" @sortReviews="sortReviews" />

    <div class="review-cards-list">
      <CompanyReviewCard
        v-for="(review, i) in searchedReviews"
        :key="i"
        :company-review="review"
        @click="$router.push({ name: 'CompanyPage', params: { id: review.companyId } })"
      />
    </div>

    <v-progress-circular
      v-if="isLoading"
      class="loader-main"
      size="50"
      width="5"
      color="var(--background-accept-color)"
      indeterminate
    />

    <div v-if="isEmpty" class="empty">
      Your request did not match any reviews...
    </div>

    <ScrollPaginationObserver v-if="isRenderScrollPagination" @loadMore="loadMore" />
  </div>
</template>

<script>
import api from '@/api'
import ReviewsSearch from '@/components/ReviewsSearch'
import ReviewsSort from '@/components/ReviewsSort'
import CompanyReviewCard from '@/components/CompanyReviewCard'
import ScrollPaginationObserver from '@/components/ScrollPaginationObserver'

export default {
  name: 'ReviewsBrowseByCompany',
  components: {
    ReviewsSearch,
    ReviewsSort,
    CompanyReviewCard,
    ScrollPaginationObserver
  },
  data() {
    return {
      isLoading: true,
      isSearching: false,
      isEmpty: false,
      companyReviews: [],
      searchedReviews: [],
      reviewsParams: {
        pageNumber: 0,
        pageSize: 50,
        filterByCompany: '',
        filterByJobTitle: '',
        sortBy: ''
      },
      isCanShowMore: false
    }
  },
  computed: {
    isRenderScrollPagination() {
      return !this.isEmpty && !!this.searchedReviews.length && this.isCanShowMore
    }
  },
  created() {
    this.fetchCompanyReviews()
  },
  methods: {
    async fetchCompanyReviews() {
      this.isLoading = true
      const { data } = await api.users.getCompanyReviews(this.reviewsParams)
      this.isLoading = false

      if (!data) return

      if (!this.isSearching) {
        this.companyReviews = data || []
        this.searchedReviews = [...this.searchedReviews, ...data]
        this.isCanShowMore = data.length === this.reviewsParams.pageSize
        return
      }

      this.searchedReviews = data || []
      this.isEmpty = !this.searchedReviews.length
    },
    resetReviews() {
      this.isEmpty = false
      this.searchedReviews = []
      this.reviewsParams.pageNumber = 0
      this.fetchCompanyReviews()
    },
    sortReviews(sortTitle) {
      this.reviewsParams.sortBy = sortTitle
      this.resetReviews()
    },
    searchReviews({ company, jobTitle }) {
      this.isSearching = true
      this.reviewsParams.filterByCompany = company
      this.reviewsParams.filterByJobTitle = jobTitle
      this.resetReviews()
    },
    loadMore() {
      this.reviewsParams.pageNumber += 1
      this.fetchCompanyReviews()
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/style/mixins.scss";

.tab-content {
  @include default-tab-content;

  background-color: var(--background-tertiary-color);
}

.review-cards-list {
  @include default-review-cards-list;
}

.empty {
  margin: auto;
  width: fit-content;
}
</style>
