<template>
  <div class="company-review-card" @click="$emit('click')">
    <div class="card-header">
      <div class="logo-and-name">
        <img
          v-if="companyReview.logo"
          :src="`/img/companies/${companyReview.logo}`"
          :alt="companyReview.company"
          class="company-img"
        >
        <img
          v-else
          src="/img/companies/default-logo.png"
          alt=""
          class="company-img"
        >
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <span
              v-bind="attrs"
              class="company-name"
              v-on="on"
            >
              {{ companyReview.company }}
            </span>
          </template>
          <span>{{ companyReview.company }}</span>
        </v-tooltip>
      </div>
      <v-rating
        readonly
        dense
        hide-details
        size="35"
        empty-icon="mdi-star"
        :value="companyReview.rateGeneral"
        color="var(--rating-star-color)"
        background-color="var(--contrast-text-color)"
        class="card-rating"
      />
    </div>
    <div class="card-body">
      <div v-for="(phase, i) in phasesRates" :key="i" class="phase-rate">
        <span>{{ phase.rate || 0 }}</span>
        <v-icon size="18" color="var(--text-color)">
          mdi-star
        </v-icon>
        <span>{{ phase.title }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CompanyReviewCard',
  props: {
    companyReview: {
      type: Object,
      required: true
    }
  },
  computed: {
    phasesRates() {
      // TODO refactor when info about companies will be clear
      const {
        rateApplication,
        rateScreening,
        rateInterviewI,
        rateInterviewII,
        rateOffer
      } = this.companyReview

      const ratePhases = [
        {
          title: 'Application',
          rate: rateApplication
        },
        {
          title: 'Screening',
          rate: rateScreening
        },
        {
          title: 'Interview I',
          rate: rateInterviewI
        },
        {
          title: 'Interview II',
          rate: rateInterviewII
        },
        {
          title: 'Offer',
          rate: rateOffer
        }
      ]

      return ratePhases
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/style/mixins.scss";

.company-review-card {
  overflow: hidden;
  border-radius: var(--border-radius-default-sm);
  width: 258px;
  box-shadow: var(--text-shadow-default);
  cursor: pointer;

  @include breakpoint-reverse(small) {
    width: 100%;
  }

  .card-header {
    padding: 12px;
    color: var(--contrast-text-color);
    background-color: var(--background-primary-color);

    .logo-and-name {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
    }

    .company-img {
      margin-right: 15px;
      width: 55px;
      height: 55px;
      padding: 2px;
      background-color: var(--background-secondary-color);
      border-radius: var(--border-radius-small);
      object-fit: contain;
    }

    .company-name {
      overflow: hidden;
      width: fit-content;
      font-weight: var(--font-weight-bold);
      font-size: 32px;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .card-rating {
      display: flex;
      justify-content: center;
    }
  }

  .card-body {
    display: grid;
    padding: 22px 0 28px;
    background-color: var(--background-secondary-color);
    gap: 7px;

    .phase-rate {
      display: grid;
      align-items: center;
      grid-template-columns: 22px 16px auto;
      padding: 2px 24px;
      font-size: var(--font-size-base-sm);
      gap: 10px;

      &:nth-child(odd) {
        background-color: var(--background-highlight-color);
      }

      &:nth-child(even) {
        background-color: var(--background-tertiary-color);
      }
    }
  }
}
</style>
