<template>
  <div class="rate-phase-block">
    <span v-if="!noTitle" class="block-title">{{ phaseData.title }}</span>
    <AppRadioGroup title="Have you heard back?" :value.sync="phaseData.data.hasHeardBack" />
    <AppInput
      v-for="(question, i) in phaseData.data.questionList"
      :key="i"
      label="Add a question you were asked:"
      type="text"
      :value.sync="question.text"
      class="question-input"
    />
    <AppButton
      width="214"
      height="34"
      class="add-question-button"
      dark
      color="var(--primary-button-text-color)"
      @click="$emit('showOneMoreQuestionInput', phaseData.id)"
    >
      Add Another Question
    </AppButton>
    <RateAndDescription
      :current-step-short-title="currentStepShortTitle"
      :rate.sync="phaseData.data.rate"
      :description.sync="phaseData.data.description"
    />
  </div>
</template>

<script>
import RateAndDescription from '@/components/RateAndDescription'

export default {
  name: 'RatePhaseBlock',
  components: {
    RateAndDescription
  },
  props: {
    phaseData: {
      type: Object,
      required: true
    },
    currentStepShortTitle: {
      type: String,
      required: true
    },
    noTitle: {
      type: Boolean,
      required: false,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
.block-title {
  display: block;
  margin-bottom: 24px;
  font-weight: var(--font-weight-bold);
  font-size: var(--font-size-sub);
}

.question-input {
  margin-top: 17px;
}

.add-question-button {
  margin-top: 17px;

  &::v-deep .text {
    font-weight: var(--text-font-light);
    font-size: var(--font-size-base-sm);
    text-shadow: none;
    color: var(--text-color);
  }
}
</style>
