<template>
  <v-dialog
    v-model="isOpen"
    transition="slide-y-transition"
    max-width="638"
    content-class="review-rate-modal"
    overlay-color="var(--overlay-bg-color)"
    overlay-opacity="1"
    @click:outside="[$emit('update:isReviewsRateModalOpen', false), currentStep = 1]"
  >
    <div class="header-wrapper">
      <div class="top-banner">
        <span class="modal-title">Rate Your...</span>
        <button class="close-button" @click="[$emit('update:isReviewsRateModalOpen', false), currentStep = 1]">
          <img src="/img/icons/plus-black.svg" alt="">
        </button>
      </div>
      <ul class="breadcrumbs">
        <li
          v-for="breadcrumb in breadcrumbs"
          :key="breadcrumb.id"
          class="breadcrumb-item"
          @click="currentStep = breadcrumb.id"
        >
          <span class="breadcrumb-item-title" :class="{ 'active': currentStep === breadcrumb.id }">
            {{ breadcrumb.title }}
          </span>
        </li>
      </ul>
      <div class="modal-review-info">
        <div class="company-name">
          <span class="company-name-title">Company</span>
          <span class="company-name-value">{{ review.company }}</span>
        </div>
        <div class="job-name">
          <span class="job-name-title">Job Title</span>
          <span class="job-name-value">{{ review.jobTitle }}</span>
        </div>
      </div>
    </div>
    <div class="modal-main">
      <transition name="slide" enter-active-class="animated-transition" :enter-class="transitionEnterClass">
        <keep-alive>
          <component
            :is="getActiveComponent"
            :current-step-short-title="currentStepShortTitle"
            :ticket-id="review.ticketId || review.id"
            :event-hub="eventHub"
          />
        </keep-alive>
      </transition>
    </div>
    <div class="modal-footer">
      <div class="steps-controls">
        <AppButton
          v-if="currentStep > 1"
          width="165"
          height="34"
          outlined
          color="var(--background-primary-color)"
          class="prev-button"
          @click="currentStep -= 1"
        >
          Back
        </AppButton>
        <AppButton
          v-if="currentStep === 3"
          width="165"
          height="34"
          dark
          color="var(--background-primary-color)"
          class="submit-review-button"
          @click="[submitReview(), closeModal()]"
        >
          Submit Review
        </AppButton>
        <AppButton
          v-else
          width="165"
          height="34"
          dark
          color="var(--background-primary-color)"
          class="next-button"
          @click="nextStep"
        >
          Next
        </AppButton>
      </div>
    </div>
  </v-dialog>
</template>

<script>
import Vue from 'vue'
import RateApplicationExperience from '@/components/RateApplicationExperience'
import RateScreeningAndInterviews from '@/components/RateScreeningAndInterviews'
import RateOfferOnboarding from '@/components/RateOfferOnboarding'

export default {
  name: 'ReviewsRateModal',
  components: {
    RateApplicationExperience,
    RateScreeningAndInterviews,
    RateOfferOnboarding
  },
  props: {
    review: {
      type: Object,
      required: true
    },
    isReviewsRateModalOpen: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      isOpen: false,
      breadcrumbs: [
        {
          id: 1,
          title: 'Application Experience',
          shortTitle: 'application',
          component: 'RateApplicationExperience'
        },
        {
          id: 2,
          title: 'Screening and Interviews',
          shortTitle: 'screening',
          component: 'RateScreeningAndInterviews'
        },
        {
          id: 3,
          title: 'Offer & Onboarding',
          shortTitle: 'offer',
          component: 'RateOfferOnboarding'
        }
      ],
      currentStep: 1,
      transitionEnterClass: '',
      currentStepShortTitle: '',
      eventHub: new Vue()
    }
  },
  computed: {
    getActiveComponent() {
      const activeBreadcrumb = this.breadcrumbs.find(breadcrumb => breadcrumb.id === this.currentStep)
      this.setShortTitle(activeBreadcrumb.shortTitle)
      return activeBreadcrumb.component
    }
  },
  watch: {
    isReviewsRateModalOpen(value) {
      this.isOpen = value
    },
    currentStep(newVal, oldVal) {
      this.transitionEnterClass = newVal > oldVal ? 'slide-in-right' : 'slide-in-left'
    }
  },
  methods: {
    setShortTitle(title) {
      this.currentStepShortTitle = title
    },
    nextStep() {
      this.currentStep += 1
      this.submitReview()
    },
    submitReview() {
      if (this.currentStepShortTitle === 'application') {
        this.eventHub.$emit('createApplicationReview')
      }

      if (this.currentStepShortTitle === 'screening') {
        this.eventHub.$emit('createScreeningInterviewReview')
      }

      if (this.currentStepShortTitle === 'offer') {
        this.eventHub.$emit('createOfferReview')
      }
    },
    closeModal() {
      this.$emit('resetReviews')
      this.$emit('update:isReviewsRateModalOpen', false)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/style/mixins.scss";

.v-dialog__content::v-deep .review-rate-modal {
  @include default-modal-wrapper;
}

.header-wrapper {
  position: sticky;
  top: 0;
  z-index: 5;
}

.top-banner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top-left-radius: var(--border-radius-default);
  border-top-right-radius: var(--border-radius-default);
  padding: 15px 25px;
  background-color: var(--background-tertiary-color);

  @include breakpoint-reverse(small) {
    padding: 5px 12px;
  }
}

.modal-title {
  font-weight: var(--font-weight-bold);
  font-size: var(--font-size-h1);
  color: var(--text-color);
}

.close-button {
  position: absolute;
  right: 15px;
  width: 24px;
  height: 24px;
  transform: rotate(45deg);
}

@include review-modal-breadcrumbs;

.modal-review-info {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 30px;
  padding: 25px;
  border-bottom: 1px solid var(--secondary-border-color);

  @include breakpoint-reverse(small) {
    margin-bottom: 15px;
    padding: 5px 12px;
  }

  .company-name,
  .job-name {
    display: grid;

    &-title {
      font-weight: var(--font-weight-bold);
      font-size: var(--font-size-base-sm);
    }
  }
}

.modal-main {
  @include default-modal-main;

  position: relative;
  font-weight: var(--font-weight-light);
}

.modal-footer {
  position: sticky;
  bottom: 0;
  z-index: 5;
  padding: 37px 25px;
  background-color: var(--background-tertiary-color);

  @include breakpoint-reverse(small) {
    padding: 12px;
  }

  .steps-controls {
    display: flex;
    align-items: center;

    @include breakpoint-reverse(small) {
      & > .app-button {
        width: fit-content !important;
      }
    }
  }

  .prev-button,
  .next-button,
  .submit-review-button {
    &::v-deep .text {
      font-weight: var(--font-weight-normal);
      font-size: var(--font-size-sub);
      line-height: var(--line-height-xs);
      text-shadow: none;
    }
  }

  .submit-review-button,
  .next-button {
    margin-left: auto;
  }

  .next-button {
    &::v-deep .text {
      color: var(--primary-button-text-color);
    }
  }

  .submit-review-button {
    &::v-deep .text {
      font-weight: var(--font-weight-bold);
      color: var(--background-highlight-darken-color);
    }
  }
}
</style>
