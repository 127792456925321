<template>
  <div>
    <v-progress-circular
      v-if="loading"
      class="loader-main"
      size="50"
      width="5"
      indeterminate
    />
    <template v-else>
      <AppRadioGroup title="Have you heard back?" :value.sync="reviewApplication.hasHeardBack" />
      <RateAndDescription
        :current-step-short-title="currentStepShortTitle"
        :rate.sync="reviewApplication.rate"
        :description.sync="reviewApplication.description"
      />
    </template>
  </div>
</template>

<script>
import api from '@/api'
import RateAndDescription from '@/components/RateAndDescription'

export default {
  name: 'RateApplicationExperience',
  components: {
    RateAndDescription
  },
  props: {
    currentStepShortTitle: {
      type: String,
      required: true
    },
    eventHub: {
      type: Object,
      required: true
    },
    ticketId: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      reviewApplication: {
        description: '',
        hasHeardBack: null,
        rate: 0
      },
      loading: false
    }
  },
  watch: {
    ticketId() {
      this.fetchApplicationReview()
    }
  },
  created() {
    this.eventHub.$on('createApplicationReview', this.createApplicationReview)
    this.fetchApplicationReview()
  },
  destroyed() {
    this.eventHub.$off('createApplicationReview', this.createApplicationReview)
  },
  methods: {
    async createApplicationReview() {
      const { error } = await api.users.createApplicationReview(this.ticketId, this.reviewApplication)
      if (error) console.error(error)
    },
    async fetchApplicationReview() {
      this.loading = true
      const { data } = await api.users.getApplicationReview(this.ticketId)
      this.loading = false

      if (data) this.reviewApplication = data
    }
  }
}
</script>
