<template>
  <div>
    <AppTextarea
      v-model="descriptionValue"
      label="Description"
      rows="5"
      class="description-textarea"
      placeholder="Speak your mind."
    />
    <div class="rate-experience">
      <span class="rate-title">Rate your {{ currentStepShortTitle }} experience</span>
      <v-rating
        v-model="rateValue"
        hide-details
        dense
        size="27"
        color="var(--rating-star-color)"
        background-color="var(--secondary-border-color)"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'RateAndDescription',
  props: {
    currentStepShortTitle: {
      type: String,
      required: true
    },
    rate: {
      type: Number,
      required: true
    },
    description: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      descriptionValue: this.description,
      rateValue: this.rate
    }
  },
  watch: {
    descriptionValue() {
      this.$emit('update:description', this.descriptionValue)
    },
    rateValue() {
      this.$emit('update:rate', this.rateValue)
    }
  }
}
</script>

<style lang="scss" scoped>
.description-textarea {
  margin-top: 20px;
}

.rate-experience {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 27px;
  gap: 24px;
}

.rate-title {
  text-transform: lowercase;

  &::first-letter {
    text-transform: uppercase;
  }
}
</style>
